// A mobile app to display interactive trail guide content.
// Copyright (C) 2021-2023  David Lougheed
// See NOTICE for more information.

import {StyleSheet} from "react-native";

export const pageStyles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
