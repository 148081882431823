// Generated automatically by trail-guide-content-server
// at 2024-09-11T23:14:53.939257
export default {
    "image": {
        "046d5528-c9cd-4484-bb1e-57da411fa938": require("./image/1654283739364-frogpheno-header.jpg"),
        "057bdb42-d0be-492f-ac48-1bc6e3c4ccb1": require("./image/1657031379205-northern_map_turtle_-_philina_english.jpg"),
        "121a0ccd-3744-4f0c-b5bb-20d6783261bd": require("./image/1657031002810-Elbow_Lake_Beach.jpg"),
        "147b4d37-9976-4334-9d31-442dc71c40df": require("./image/1656967235442-Grey_Ratsnake_Cuteheader.jpg"),
        "248df5b7-5ee8-4224-a499-0f4a21d7a783": require("./image/1657031369212-Food_web_for_app_station.jpg"),
        "2c0dcba9-604e-410d-95f4-1e8bb6c5c84f": require("./image/1657288186889-Cody_Houle-_Forest.jpeg"),
        "325524cc-963e-4942-9c8d-946c8c587f31": require("./image/1657029312024-Downy_Woodpecker_in_Snag.jpg"),
        "347b3b77-bbb7-4e61-be13-71f9016db28e": require("./image/1656964260119-Plated_Rock_Tripe.jpg"),
        "364618be-31bd-4627-8033-6b44eac51d43": require("./image/1654283739056-climateland.jpg"),
        "3d08f938-02ca-419c-af80-737636b7318a": require("./image/1654283739234-fred_pondmussel.jpg"),
        "3dcb0674-71a5-4bff-bd93-8f11c52de66d": require("./image/1656955516501-shagbark-hickory-nut.jpg"),
        "3e256739-df0e-4da4-a3ad-830c9ac944da": require("./image/1654283739191-blandings1.jpg"),
        "3e71ea6f-fc7c-480a-9819-755920792129": require("./image/1666050298249-aerialovertime_1962.jpg"),
        "45177ab5-f41d-4682-a813-ed1b4e108aaf": require("./image/1654283739203-ironwood.jpg"),
        "4857a49c-8c21-4957-bd78-93aa932ad134": require("./image/1656955888131-Shagbark_hickory.jpg"),
        "48639feb-7b41-418f-a190-30485b130f42": require("./image/1654283739031-climons-header.jpg"),
        "49bcd570-c4aa-4a85-8a8a-e7f5018f9d28": require("./image/1654283738994-trailcamera_deerseq_3.jpg"),
        "4ea1b5f5-4f1a-4267-9118-872ea3483989": require("./image/1654283739314-geology-header.jpg"),
        "50b1f3d6-afcd-4905-b194-2684ddace543": require("./image/1654283738984-trailcamera_deerseq_2.jpg"),
        "50c4da23-bd55-4a1e-abf3-172aa527e1e7": require("./image/1654283739169-twsedge.jpg"),
        "545fbef4-94be-4660-a4a1-1a721325923b": require("./image/1654283739136-wetland-header.jpg"),
        "56caae74-4f3a-47b3-b938-091a0d01a3ac": require("./image/1657032740619-Buckthorn_fruit_and_leaves_NCC.jpg"),
        "5c227763-06db-414f-96a4-e4c74d850ad9": require("./image/1656955501954-Shagbark_hickory_Carya_ovata_8-22-16_1.jpg"),
        "5cc9cc55-84db-49dc-a848-4757388131e2": require("./image/1656964271234-Monks_hood.jpg"),
        "60d44159-afaa-4c7e-80f1-0c9b127d41f6": require("./image/1654283738913-trailcamera_deer.jpg"),
        "64241a96-0053-436a-85b7-37ec80ce177b": require("./image/1654283739751-WTSP.jpg"),
        "658bfe27-ee32-477b-95ec-54acc7b43f43": require("./image/1654283739331-hsspec-header.jpg"),
        "66a07007-bb47-4580-95c3-896ecd34e088": require("./image/1654283739757-GCTH.jpg"),
        "689d86a4-da36-49bd-8665-bba0c3f6ac6d": require("./image/1656966402304-Greyratsnake_face.jpg"),
        "696dd516-12dd-469f-ae10-79c2fd330dbf": require("./image/1654283739784-feederw-header.jpg"),
        "69ca1744-c5c5-4f25-9f26-d31ff9608c7e": require("./image/1666049564224-map_2054.jpg"),
        "6c1ea0bc-8be3-4903-bb82-d70cec0942ec": require("./image/1654283739603-1878.jpg"),
        "6e547ee7-c47c-4678-8ca9-4096a6f74935": require("./image/1654283739112-plso-header.jpg"),
        "701b6d85-0233-471e-9dec-282217839af2": require("./image/1654283739409-samplingarea.jpg"),
        "70afa8f9-c50c-446d-920f-74aa2bf5914c": require("./image/1654283739618-1972_dams.jpg"),
        "716eb34b-46a1-4724-895e-dffeff9b1bea": require("./image/1654283739469-dsv-header.jpg"),
        "71f26954-7b5d-4f59-8d28-99371a515615": require("./image/1654283739218-eastpmus-header.jpg"),
        "72fa1d80-2978-4254-84ff-f04cc8e63b72": require("./image/1654283739343-oldhouse_winter.jpg"),
        "748d02f4-9aec-4d73-af7f-c259254b8c64": require("./image/1654283738951-trailcamera_redsquirrel_annotated.jpg"),
        "7598100e-3eb7-4a1d-a6aa-2014428c9802": require("./image/1649881896986-Heart_Berry_no_border.jpg"),
        "75ce6143-305f-42f0-a1dd-6fc4625a47c9": require("./image/1657032770710-GarlicMustard_NCC.jpg"),
        "764ecc34-dc95-411d-b960-d061fea9cdc9": require("./image/1654283738963-trailcamera_riverotter.jpg"),
        "7ff9349a-5602-45b3-b317-d669bce5386f": require("./image/1656964298714-Frosted_Rock_Tripe.jpg"),
        "802e69e8-03c7-40d8-9d1c-a3197bbdb73d": require("./image/1654283739525-eurofb-header.jpg"),
        "86bea2f7-4e61-49f0-a082-bfe855a8bd00": require("./image/1654283739062-winter.jpg"),
        "898f8bda-adca-4579-a0de-8e0e08410797": require("./image/1657288170515-Cody_Houle_Wetland_.jpeg"),
        "8b4ba907-991b-4839-b78c-dbdd9fab613e": require("./image/1654283738905-deerrub.jpg"),
        "8b763faa-da4a-4c1b-bce1-60c0c7819ca5": require("./image/1654187231340-invasives.jpg"),
        "8be22f32-cbc0-45d3-9ad4-04d612cc6a9a": require("./image/1656945788484-Tagged_Tree_Over_Time_Plot.png"),
        "91c5e674-af28-48a7-a7f5-5cc25fa27968": require("./image/1654283739276-whitepine-needles.jpg"),
        "95458230-7bdc-4b5e-8ae0-50b467f83ca4": require("./image/1654283738921-trailcamera_twodeer.jpg"),
        "962cd10d-b562-4a2d-b272-afc6a3370612": require("./image/1654283738944-trailcamera_porcupine.jpg"),
        "9fd2d9aa-11f1-438b-8929-6f5e4e5bcb7f": require("./image/1654283739253-eastwp-header.jpg"),
        "a1725635-69b3-495b-a68c-25bb9464ecbc": require("./image/1656964284124-Cladonia_Sp._Cup_Lichen.jpg"),
        "a319a282-1f69-47cf-8e4f-0750f958d30b": require("./image/1654283738932-trailcamera_lepus.jpg"),
        "a41b2750-b304-47b8-814c-19379e01c257": require("./image/1656956118999-shagbark-hickory-bark1.jpg"),
        "a790c9c5-4e56-46ef-bea2-18fc2ed7bb3d": require("./image/1654283739049-locations.png"),
        "a8185143-ec61-4ea0-8af9-bce013f79055": require("./image/1654283739076-quiz.jpg"),
        "a903ca02-63e5-490d-b15b-f2ba08deee4d": require("./image/1654283739199-log-header.jpg"),
        "aad58a8f-34d0-4134-900e-a8e918502f6a": require("./image/1654283739095-eman-header.jpg"),
        "af0edb67-f892-4b5a-8ed0-57bac944e015": require("./image/1657031253532-Watersheds.jpg"),
        "b2eff238-df6b-44f9-bc95-6ab216ccd4d8": require("./image/1654283739497-buckthorncontrol.jpg"),
        "b3ce5b40-ff41-49d0-87eb-519389ee7a9d": require("./image/1654283739014-trailcamera_deerseq_5.jpg"),
        "b82881f9-a0e0-404b-a32b-cfeb69e8e1fa": require("./image/1666050309316-aerialovertime_1978.jpg"),
        "b8c5c252-df81-431f-a511-e58f5230a52e": require("./image/1654283739415-peeper_calling.jpg"),
        "b956b94e-84b5-442a-96a2-c513f1a5d14d": require("./image/1654283739300-eleec.png"),
        "bab09243-421c-4a72-bbc9-9d49f59d469c": require("./image/1654283738974-trailcamera_deerseq_1.jpg"),
        "bb36232a-8974-4629-bf30-4558b9b7fb35": require("./image/1657028428841-Redshafted_northern_flickers_snag.jpg"),
        "be3ffc53-c0db-4914-9cd6-ab6ddc28291a": require("./image/1654283739430-boreal_chorus_frog_cairns.jpg"),
        "bf610e92-3e7e-4f4d-990d-6f2f8aaf178d": require("./image/1663611996123-creation_kinship_world_on_turtles_back_2015.jpg"),
        "c0527eba-d820-4dd0-8928-d2d6e4c340b0": require("./image/1666050287771-aerialovertime_1953.jpg"),
        "c0941d90-8913-4823-879c-e08f038a2150": require("./image/1656966980654-Young_grey_ratsnake.jpg"),
        "c7d2a1e5-91f2-4ce8-841e-8f674e4d4c5f": require("./image/1663609108680-TD_Signage_Project_Border-3.png"),
        "d11d1b2d-9d6a-4e1a-9b97-f326eaed1c1c": require("./image/1657029353169-Slime_mould_on_log.jpg"),
        "d5d84468-6a6b-4196-8c88-d69b120a88c5": require("./image/1654283739227-pondmussel.jpg"),
        "d781cbfc-00ac-49dc-838e-7e2cd54f2e7d": require("./image/1654283739437-graytreefrog.jpg"),
        "db2b5acd-628c-4698-8987-f7df82bb44e4": require("./image/1654283739570-eldam-header.jpg"),
        "dfd07768-590b-42c8-a68e-2bad5decbc77": require("./image/1654283739003-trailcamera_deerseq_4.jpg"),
        "e110a773-2dbb-4107-85e8-f836f5ae578d": require("./image/1654283739767-hermitthrush.jpg"),
        "e35a19df-8038-44e5-bc94-92b41f110bc7": require("./image/1666026366848-rock_barren.jpg"),
        "e52a9be9-5b64-4c0c-8fe8-7524d39b9997": require("./image/1654283739592-bathymetry.png"),
        "ec32c5e7-2e1c-4995-a132-837544c388cc": require("./image/1657029324784-Chipping_sparrow_on_snag.jpg"),
        "ec7ecb7f-2502-4d92-8ddb-73ed1a978c3a": require("./image/1656967011289-Northern_Watersnake.jpg"),
        "ece3ec31-e963-476b-8a6b-28e5df610bdd": require("./image/1654283738889-eastwc-header.jpg"),
        "f001d938-7842-4702-9f17-7f776b74d913": require("./image/1657032755070-elbow_periwinkle.jpg"),
        "f184164a-0e22-441b-bf72-66e765147c3c": require("./image/1654283739352-land1878.jpg"),
        "f433580e-eff2-438e-a022-5735ec21f538": require("./image/1664385181629-TD_Signage_Project_Border-3.png"),
        "f794c026-f894-4f1b-a476-28d04a9f8230": require("./image/1657029290719-Scaly_Pholiota.jpg"),
        "f7b438bb-866d-445e-9911-b4f00d9c90ba": require("./image/1654283739443-wood_frog.jpg"),
        "f89def40-c8f6-425d-b37e-9e86ac7b7e01": require("./image/1654283739702-oldbird-header.jpg"),
        "f9d6c545-f78d-4efe-99a2-23f4817b25de": require("./image/1654283739067-exdata.png"),
        "fa2e3696-7a79-43bd-859f-166a3efc8c58": require("./image/1656964248349-Leathery_Rock_Tripe.jpg"),
        "fb2341c0-2bad-4e82-b5f7-260d003750f1": require("./image/1664509800354-TDFEF_Lockup_ENG_Transparent.png"),
        "fb4f858f-ff2a-42bb-9107-24da7ebc2bff": require("./image/1654283739625-bathymetry.png"),
    },
    "audio": {
        "063faf79-7ca8-424d-a344-1a68e3f77e44": require("./audio/1663601343271-A_Moss-MP3.mp3"),
        "080f5220-2e2c-4f44-9ead-47d741de2193": require("./audio/1664504354728-K_Wild_Raspberries.mp3"),
        "0bd74de0-28e6-481e-8046-aa995a6f26b4": require("./audio/1664503690057-K_Deer-MP3.mp3"),
        "0ca59c18-e6d0-49f3-bfb2-4936d95e96d3": require("./audio/1664503935323-K_Plantain-MP3.mp3"),
        "0ce835d7-35e6-4b76-85aa-8217f66fddcd": require("./audio/1663599794334-A_Water.mp3"),
        "0cec81f0-0f2b-409d-b237-388600d1c37c": require("./audio/1664504006516-K_Red_Oak-MP3.mp3"),
        "1060d2e2-24f4-495b-b1cd-7fb56a7be0f9": require("./audio/1663599660552-A_Cattail.mp3"),
        "106694ef-945b-4a9c-9669-bbe154dd8e3a": require("./audio/1664504323644-K_Wetland_Habitat.mp3"),
        "1210f6ba-29e6-4276-9486-663150085056": require("./audio/1664504294203-K_Water-MP3.mp3"),
        "155e8801-ce04-4bb9-9cb1-8189447cd01a": require("./audio/1664503983044-K_Raven-MP3.mp3"),
        "167b287d-df2a-409e-adac-34e19d92f589": require("./audio/1664504221667-K_Strawberry-MP3.mp3"),
        "16def576-5fe0-4edd-956b-99ab42df48a8": require("./audio/1654283739393-h_versicolor.mp3"),
        "17e168e9-9b05-4be2-ad34-753d8a82306b": require("./audio/1664503921717-K_Mullein-MP3.mp3"),
        "1997f340-32ae-4f3a-b0b6-09146a4ad547": require("./audio/1664503965415-K_Rabbit-MP3.mp3"),
        "1ce1b545-1bd3-4ed5-aca0-fd29d7bf5a12": require("./audio/1666049728129-strawberry_story_english_small.mp3"),
        "1e36934c-f3eb-4dc3-9753-0b614ca72dfa": require("./audio/1664503601549-K_Blue_Heron.mp3"),
        "2086ea66-5dee-40b7-b6e8-f32c06a8b0dd": require("./audio/1664503743655-K_Forest_Habitat-MP3.mp3"),
        "24739f5c-2aab-4339-8650-24fc951547e0": require("./audio/1664503493174-A_Welcome.mp3"),
        "2d09349b-5ada-4d1f-8858-3b4709b60f3c": require("./audio/1664503722328-K_Duck-MP3.mp3"),
        "2dc7d560-f9d2-4165-b84e-3fcacf5bc878": require("./audio/1663600811592-A_Duck-MP3.mp3"),
        "30bb3aed-e1a1-4551-8491-65aa10fc7dd8": require("./audio/1663600072087-A_Mullen.mp3"),
        "351461cd-b2fd-42e2-aaca-42ff4811722b": require("./audio/1664504266340-K_Thank_You.mp3"),
        "42094bb2-799f-40a9-96c4-8753698129a9": require("./audio/1663601300254-A_Anishinaabe-MP3.mp3"),
        "42eb4b5c-59a8-4852-b417-c57854074fbb": require("./audio/1663599856776-A_Rocks.mp3"),
        "43650c55-a4de-4cb0-8443-1574ee66d817": require("./audio/1663599417797-A_Red_Willow.mp3"),
        "4b9e58ba-d605-4d2c-bffc-870fe4adf35d": require("./audio/1663599322985-A_Solomans_Seal.mp3"),
        "4df083e6-8033-4545-b19e-2884c119ad20": require("./audio/1663600824529-A_Downy_Woodpecker-MP3.mp3"),
        "4e3e34ca-34a7-435a-ab3c-df4a037ba6bc": require("./audio/1654283739378-p_crucifer.mp3"),
        "4f800809-42c6-4fcb-99b3-f476c551f05f": require("./audio/1663601211263-A_Nanaboozoo_and_the_Ducks-MP3.mp3"),
        "52c918de-a8ef-4282-9cc8-50c43f32d009": require("./audio/1663599870227-A_Porcupine.mp3"),
        "5495541b-6077-4438-bdaf-ab51290c74f8": require("./audio/1664503460269-A_Raven.mp3"),
        "599d43db-e522-4647-9094-c43bc3f98762": require("./audio/1664504309219-K_Welcome.mp3"),
        "5c39b0f8-ae6e-472b-a8fa-0f9176a2563e": require("./audio/1664503668195-K_Dandelion-MP3.mp3"),
        "60452e41-bdfd-4ce7-be69-d5d36919828c": require("./audio/1664503888248-K_Moss.mp3"),
        "643bc5c9-2850-4231-98e7-0fa81d40c66a": require("./audio/1664509145303-Barbara-Nolan-ducks-english-MP3_small.mp3"),
        "64b0cd99-f80d-43af-bff1-b7bd1c404364": require("./audio/1664509212116-Barbara-Nolan-woodpecker-in-English_small.mp3"),
        "66fce325-f8e8-412e-a12a-04ab232b5a51": require("./audio/1666049323149-strawberry_story_kanyenkeha_small.mp3"),
        "67360e05-9baf-4aff-92e6-09e15ba107c6": require("./audio/1663600002875-A_Dandilion.mp3"),
        "6ac5d6ea-3fa8-45b7-9b98-af4a4195689b": require("./audio/1664504113109-K_Snake-MP3.mp3"),
        "6cd6ee1b-86af-4f35-b9bf-3ae33c39f936": require("./audio/1663600795446-A_Forest_Habitat-MP3.mp3"),
        "6d5158f2-b48f-4eb4-9844-5f10a1858c1e": require("./audio/1663600863529-A_Wild_Raspberry-MP3.mp3"),
        "6e419fe9-8352-4788-8eca-154553f50150": require("./audio/1664503440103-A_Redwinged_Blackbird.mp3"),
        "73b418fb-26bf-4002-bda8-b42392fa7382": require("./audio/1663600487922-A_Turtle-MP3.mp3"),
        "7514e923-5486-42e6-acb7-17ec472d7075": require("./audio/1654283739386-p_maculata.mp3"),
        "7726207d-1079-40aa-9828-3bcbb36bf520": require("./audio/1664504186038-K_Squirrel-MP3.mp3"),
        "7cc0f132-087a-4144-920b-87a3dc801234": require("./audio/1664503950188-K_Porcupine-MP3.mp3"),
        "7e664e10-a492-4290-a5bd-16392d36d59e": require("./audio/1664503642800-K_Cattail.mp3"),
        "7ed5d64f-b8c0-49c0-9e08-60f78c561e5a": require("./audio/1663600201281-A_Strawberry.mp3"),
        "80f0452d-69ff-4b35-8364-eff9590dbae0": require("./audio/1664504056204-K_Rock_Barren_Habitat.mp3"),
        "8239246d-7b8b-4564-9038-14d202789fc2": require("./audio/1663601263967-A_Soil-MP3.mp3"),
        "8359e27f-978b-472f-8758-5d4d23b6540c": require("./audio/1663599973044-A_White_Pine.mp3"),
        "83e3c20d-ae3c-4889-874e-9db7165aac84": require("./audio/1664504091169-K_Shagbark_Hickory-MP3.mp3"),
        "853b24b7-c64c-4b95-8c6c-3df4d8625459": require("./audio/1663601283886-A_Hawk-MP3.mp3"),
        "8bcb87b0-ce2d-46ea-a6b4-b32b1c7a6a8c": require("./audio/1664504076078-K_Rocks-MP3.mp3"),
        "91e8e97b-0ad2-432f-b924-7866d4481c03": require("./audio/1663600849445-A_Anishinaabemowin-MP3.mp3"),
        "98ba76c9-2d90-42b9-8ec5-7d72f9041c56": require("./audio/1664504206707-K_Strawberry_Story.mp3"),
        "9962453e-59a6-437f-9db1-8657fb8a2442": require("./audio/1663600764131-A_Beaver-MP3.mp3"),
        "99806c42-ab44-417f-8825-2424f86b639e": require("./audio/1664504022535-K_Red_Willow-MP3.mp3"),
        "9af581a2-5e26-4450-93c6-b4309f6586cf": require("./audio/1663601330295-A_Mouse-MP3.mp3"),
        "9f000c97-921c-4856-8e91-6597f2de8967": require("./audio/1666049161472-rocks_and_the_little_people_english_audio_clip_small.mp3"),
        "a06864d3-aec9-4146-99cc-179c86e66deb": require("./audio/1664504037645-K_Redwing_Blackbird-MP3.mp3"),
        "a141d6fe-960f-41f4-ae86-cd67b6682f9c": require("./audio/1664503582598-K_Beaver-MP3.mp3"),
        "a4551f33-3796-4fdd-ab1b-74a595f2d333": require("./audio/1664503775911-K_Hawk.mp3"),
        "a4c5d418-bc09-444a-8c9c-313c3a40bb71": require("./audio/1663600881231-A_Wetland_Habitat-MP3.mp3"),
        "a586638a-9efd-400a-97c8-16fd58e19471": require("./audio/1663600217357-A_Juniper.mp3"),
        "a6476c10-a682-4414-8229-89b6a5dc35d0": require("./audio/1664504235504-A_Sugar_Maple.mp3"),
        "a7c099f5-0bf7-43ad-a8ba-ecb9c9a94773": require("./audio/1663600031145-A_Sumac.mp3"),
        "a8c4f2d9-437f-459d-bfa6-2c38d3a4123f": require("./audio/1663599917215-A_Shagbark_Hickory.mp3"),
        "ac3afab6-0651-46d5-bd7d-d6a26ebb2268": require("./audio/1663600015000-A_Plantain.mp3"),
        "adf297bd-c1a1-4c6f-81cc-a62042290151": require("./audio/1664503543143-K_Bald_Eagle-MP3.mp3"),
        "b330e3fa-ec06-4b51-8732-4cc8243db413": require("./audio/1664509235101-Barbara-Nolan-Woodpecker-Story-A_small.mp3"),
        "b43f440a-cf6a-4ca8-a773-a7610faa9051": require("./audio/1664504374024-Kanyenkahaka.mp3"),
        "b4d0cb69-ffe1-48f9-97e4-74de098b2a18": require("./audio/1663600252196-A_Where_or_How_the_Woodpecker_Came_to_Be-MP3.mp3"),
        "b52d3e9b-9b6d-4706-a915-32c196128db1": require("./audio/1664504251989-K_Sumac-MP3.mp3"),
        "b731eb12-f8c3-49a6-b6b0-56fe499bfa86": require("./audio/1654283739402-l_sylvaticus.mp3"),
        "b8b52f51-c700-4fba-ade2-d3c8910039ab": require("./audio/1664504165192-K_Spider-MP3.mp3"),
        "b8c82d56-9294-433a-a8fe-7122dbeb82ba": require("./audio/1663599945970-A_Bear.mp3"),
        "bb79d673-c6d1-422f-8260-58062b5ca692": require("./audio/1663600088043-A_Goldenrod.mp3"),
        "c0d01e5c-24de-4b0b-b5d5-ae2496a9bc52": require("./audio/1664509174537-Barbara-Nolan-Nanabozho-and-the_ducks_A_small.mp3"),
        "c3b99eb4-bef8-4c9d-8efc-2d01c069aaf2": require("./audio/1654283739731-GCTH.mp3"),
        "cad478cf-b442-4ba2-aa5a-75bf0f6af3b9": require("./audio/1664503866998-K_Meadow_Habitat.mp3"),
        "d2e86173-12e6-4215-b085-5f9e9cf9ddfe": require("./audio/1666049002021-rocks_and_the_little_people_kenyenkeha_audio_clip_small.mp3"),
        "d92f8c43-cc89-4927-8af1-578542001d17": require("./audio/1663599817131-A_Spider.mp3"),
        "da0a8b88-e62b-4d8a-87c2-418c0953f7e2": require("./audio/1654283739720-SWTH.mp3"),
        "da9bf1cb-a12a-4c51-89db-63997ac14a50": require("./audio/1664503846035-K_Little_People.mp3"),
        "de221941-3edc-40b2-97ac-290f2ed4cff8": require("./audio/1663600463209-A_Rock_Barren_Habitat-MP3.mp3"),
        "de5f3e9a-d0f4-4e74-b08c-355836cf7777": require("./audio/1663599431042-A_Blue_Herron.mp3"),
        "dfa73a54-6ec1-4306-8621-02fcc80e1c2d": require("./audio/1654283739726-WTSP.mp3"),
        "dfbd1ba3-ab85-4712-9d91-6c950e25bd8e": require("./audio/1664503706224-K_Downy_Woodpecker.mp3"),
        "e21be66f-12c8-4089-8e9a-19cd2d64bc9c": require("./audio/1663599890585-A_Red_Oak.mp3"),
        "e3a03723-e13e-4770-9469-68e09e837cda": require("./audio/1664503757975-K_Goldenrod.mp3"),
        "e5340b18-07ea-45a3-b24e-be14c712e8ee": require("./audio/1664504282156-K_Turtle-MP3.mp3"),
        "e559a2cc-1afe-4af5-896e-57b4d218f216": require("./audio/1664503563577-K_Bear-MP3.mp3"),
        "e662a8bb-c63f-4077-891e-84a170d1d2f7": require("./audio/1664503477741-A_Thank_You.mp3"),
        "e748ce7d-67d9-408c-b6aa-d2dcdf596eef": require("./audio/1663599985027-A_Sugar_Maple.mp3"),
        "e75f8f04-8096-4dbb-bb51-d4a27ff777a8": require("./audio/1664504341684-K_White_Pine.mp3"),
        "e8b00298-9830-4164-9beb-b9a2c65c8189": require("./audio/1663599843645-A_Deer.mp3"),
        "e954a1cc-ae90-47cf-99aa-0b02cdf7fc82": require("./audio/1664504385461-Kanyenkeha.mp3"),
        "ea244bdf-10d7-4161-a195-e1d8598adbb4": require("./audio/1654283739740-HETHdescent.mp3"),
        "ed076d3b-4cee-4597-893c-3d54d50954d5": require("./audio/1663601356667-A_Snake-MP3.mp3"),
        "ed1f8b35-fb74-4fad-b205-b7f5b456bca5": require("./audio/1663599929571-A_Rabbit.mp3"),
        "ef6fef17-1bd6-4152-b60c-79cc8cea5a5c": require("./audio/1663599306824-A_Meadow_Habitat.mp3"),
        "f22a1e77-8f81-40db-b17a-adbe557af519": require("./audio/1664503904456-K_Mouse.mp3"),
        "f30a7eb2-8679-408f-ad57-05e9d1263c50": require("./audio/1664504144999-K_Solomans_Seal.mp3"),
        "f81ceac1-1b82-4d55-ac71-8808256f02e2": require("./audio/1664503824234-K_Juniper-MP3.mp3"),
        "fb0d74cf-229b-4ce7-9692-084d01298754": require("./audio/1663599804758-A_Squirrel.mp3"),
        "fbe4c9b0-73e7-4bd8-b53d-ddf803f87647": require("./audio/1664504126710-K_Soil.mp3"),
        "fdeeb7aa-2259-4a93-b8cf-1202eb1a82da": require("./audio/1663599443225-A_Bald_Eagle.mp3"),
    },
    "video": {
        "1762992c-ffd5-4855-964d-073edb749ef0": require("./video/1654283739841-goldfinch.mp4"),
        "39b62e5f-f412-4400-b375-5c44dafc6777": require("./video/1654283739161-springpeeper.mp4"),
        "6eb73866-8b9c-4c90-8aa9-360b00cc923c": require("./video/1654283739877-purplefinch.mp4"),
        "fb37b79a-a246-43de-927f-059946d758e2": require("./video/1654283739813-chickadee.mp4"),
    },
    "video_text_track": {
    },
    "pdf": {
        "2c8a3896-da2b-41a6-96d6-56262e044ada": require("./pdf/1654283739929-tallysheet.pdf"),
    },
};
